import React, { useEffect, useState } from 'react';
import { getArticle, getArticleContentTypes } from '../../../get_server_query';
import { format } from '../../components/cms-modules';
import ArticleDetail from '../../components/if-magazine/detail';
import { useLocale } from '../../context/locale.context';
import Layout from '../../modules/layout';
import SEO from '../../modules/seo';

const PreviewPage = () => {
  const { locale } = useLocale();
  const [page, setPage] = useState(null);
  const gqlUrl = `${process.env.GATSBY_CONTENTFUL_GRAPHQL_URL}/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/${process.env.GATSBY_CONTENTFUL_ENVIRONMENT}`;
  const authHeader = `Bearer SOEGLctP3y-WLjmF6YIv1juHhNn9dOBOvp98vtQuFnY`;

  useEffect(() => {
    const url = new URL(window.location);
    const fetchPage = async () => {
      // first fetch page content types to avoid query size limit
      const typesResponse = await fetch(gqlUrl, {
        method: 'POST',
        body: JSON.stringify({
          operationName: null,
          query: getArticleContentTypes(
            url.searchParams.get('componentId'),
            locale
          ),
          variables: {},
        }),
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
        },
      });
      const typesJson = await typesResponse.json();
      const includedTypes =
        typesJson.data?.article?.articleContentElements?.items.map(
          (el) => el.__typename
        );
      const res = await fetch(gqlUrl, {
        method: 'POST',
        body: JSON.stringify({
          operationName: null,
          query: getArticle(
            url.searchParams.get('componentId'),
            locale,
            includedTypes
          ),
          variables: {},
        }),
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
        },
      });

      const json = await res.json();
      setPage(format(json.data.article));
    };
    fetchPage();
  }, []);

  return page ? (
    <Layout
      hasWhiteNavigation={page.hasWhiteNavigation}
      heroTeaser={format(page.heroTeaser)}
      preview
    >
      <SEO title={page.headline} />
      <ArticleDetail data={page}></ArticleDetail>
    </Layout>
  ) : null;
};

export default PreviewPage;
